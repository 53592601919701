<template>
  <div>
    <div class="row">
      <div class="col">
        <h2>バーチャル名刺背景ジェネレーターの使い方</h2>
        <p>WEB上に必要項目を入力し任意の画像をアップロードするだけで、<br>
        オンライン会議の背景画像を生成・ダウンロードすることが可能です。<br>
        また、メールアドレスとSNSの2次元バーコード生成にも対応しており、<br>
        URLを入力すると名刺画像上には自動的に生成された2次元バーコードが印字されます。</p>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-3">
        <nav id="toc" data-toggle="toc" class="sticky-top bg-light rounded px-3">
          <ul class="nav navbar-nav">
            <li><a class="nav-link" href="#editVirtualBackgrounds">編集のしかた</a>
              <ul class="nav navbar-nav">
                <li><a class="nav-link" href="#page01">v0.3.0の変更点</a></li>
                <li><a class="nav-link" href="#page02">背景画像</a></li>
                <li><a class="nav-link" href="#page03">背景色</a></li>
                <li><a class="nav-link" href="#page04">文字色とフォント</a></li>
                <li><a class="nav-link" href="#page05">ロゴ</a></li>
                <li><a class="nav-link" href="#page06">名刺情報</a></li>
                <li><a class="nav-link" href="#page07">リンク先情報</a></li>
                <li><a class="nav-link" href="#page08">名刺画像の生成</a></li>
              </ul>
            </li>
            <li><a class="nav-link" href="#setVirtualBackgrounds">バーチャル背景の設定のしかた</a>
              <ul class="nav navbar-nav">
                <li><a class="nav-link" href="#page09">ZOOM</a></li>
                <li><a class="nav-link" href="#page10">Skype</a></li>
                <li><a class="nav-link" href="#page11">Teams</a></li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-9">
        <h3 id="editVirtualBackgrounds">編集のしかた</h3>
        <img id="page01" class="img-thumbnail mt-2" src="@/assets/images/howtouse/v0.3.0/howtouse01.jpg" alt="">
        <p><small>※開発中の画面です。実際の画面とは多少異なる場合があります。</small></p>
        <img id="page02" class="img-thumbnail mt-2" src="@/assets/images/howtouse/v0.3.0/howtouse02.jpg" alt="">
        <p><small>※開発中の画面です。実際の画面とは多少異なる場合があります。</small></p>
        <img id="page03" class="img-thumbnail mt-2" src="@/assets/images/howtouse/v0.3.0/howtouse03.jpg" alt="">
        <p><small>※開発中の画面です。実際の画面とは多少異なる場合があります。</small></p>
        <img id="page04" class="img-thumbnail mt-2" src="@/assets/images/howtouse/v0.3.0/howtouse04.jpg" alt="">
        <p><small>※開発中の画面です。実際の画面とは多少異なる場合があります。</small></p>
        <img id="page05" class="img-thumbnail mt-2" src="@/assets/images/howtouse/v0.3.0/howtouse05.jpg" alt="">
        <p><small>※開発中の画面です。実際の画面とは多少異なる場合があります。</small></p>
        <img id="page06" class="img-thumbnail mt-2" src="@/assets/images/howtouse/v0.3.0/howtouse06.jpg" alt="">
        <p><small>※開発中の画面です。実際の画面とは多少異なる場合があります。</small></p>
        <img id="page07" class="img-thumbnail mt-2" src="@/assets/images/howtouse/v0.3.0/howtouse07.jpg" alt="">
        <p><small>※開発中の画面です。実際の画面とは多少異なる場合があります。</small></p>
        <img id="page08" class="img-thumbnail mt-2" src="@/assets/images/howtouse/v0.3.0/howtouse08.jpg" alt="">
        <p><small>※開発中の画面です。実際の画面とは多少異なる場合があります。</small></p>

        <h3 id="setVirtualBackgrounds" class="mt-5">バーチャル背景の設定のしかた</h3>
        <img id="page09" class="img-thumbnail mt-2" src="@/assets/images/howtouse/v0.3.0/howtouse09.jpg" alt="">
        <img id="page10" class="img-thumbnail mt-2" src="@/assets/images/howtouse/v0.3.0/howtouse10.jpg" alt="">
        <img id="page11" class="img-thumbnail mt-2" src="@/assets/images/howtouse/v0.3.0/howtouse11.jpg" alt="">
        <img id="page12" class="img-thumbnail mt-2" src="@/assets/images/howtouse/v0.3.0/howtouse12.jpg" alt="">
        <img id="page13" class="img-thumbnail mt-2" src="@/assets/images/howtouse/v0.3.0/howtouse13.jpg" alt="">
        <img id="page14" class="img-thumbnail mt-2" src="@/assets/images/howtouse/v0.3.0/howtouse14.jpg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/assets/scss/custom.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-vue/src/index.scss';

#toc {
  .nav .nav {
    @extend .pl-3;
  }
}

.howtouse {
  h3 {
    @extend .h4;
    @extend .text-primary;
    @extend .border-bottom;
    @extend .border-primary;
    @extend .py-2;
  }
}
</style>
