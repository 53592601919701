import { render, staticRenderFns } from "./Howtouse.vue?vue&type=template&id=b51b1cb2&scoped=true"
import script from "./Howtouse.vue?vue&type=script&lang=js"
export * from "./Howtouse.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b51b1cb2",
  null
  
)

export default component.exports