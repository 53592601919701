<template>
<div class="howtouse">

  <div class="container py-5">
    <Howtouse_default v-if="setting.appId == 'default'" />
    <Howtouse_boston v-if="setting.appId == 'boston'" />
    <Howtouse_eisai v-if="setting.appId == 'eisai'" />
  </div>
  <Footer />
</div>
</template>

<script>
import Howtouse_default from '@/components/Howtouse_Default.vue'
import Howtouse_boston from '@/components/Howtouse_Boston.vue'
import Howtouse_eisai from '@/components/Howtouse_Eisai.vue'

export default {
  computed: {
    setting() {
      return this.$store.state.setting
    },
  },
  components: {
    Howtouse_default,
    Howtouse_boston,
    Howtouse_eisai
  }
}
</script>

<style lang="scss" scoped>
</style>
